export default {
    menu: (provided, state) => ({
        borderRadius: 0,
        background: "white",
        padding: "15px 0 10px 15px",
        position: "absolute",
        border: "1px solid #04001F",
        top: 60,
        left: 0,
        width: "100%",
        zIndex: 1,
        boxSizing: "border-box"
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        color: "#7C7C7C",
        background: "transparent",
        "&:hover": {
            background: "transparent",
            color: "#151515"
        },
        "&:focus": {
            background: "transparent",
            color: "#151515"
        },
        fontSize: "18px",
        padding: 0,
        marginBottom: "10px"

    }),
    control: () => ({
        minWidth: 280,
        minHeight: 61,
        display: "flex",
        border: "1px solid #7C7C7C",
        fontSize: "18px",
        marginBottom: "15px",
        padding: "10px",
        boxSizing: "border-box",
        position: "relative"
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {
            ...provided,
            opacity,
            transition
        };
    },
    indicatorSeparator: () => ({
        background: "transparent"
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        fill: "#7C7C7C"
    })
}