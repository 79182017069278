import React from 'react';
import classNames from 'classnames';
import { TextAnimationHeading } from '../../components/text-animation-heading';
import Reveal from "react-reveal/Reveal";

const dimensionsDesktop = '?w=2000&fm=jpg';
const dimensionsTablet =  '?w=2000&fm=jpg';
const dimensionsMobile =  '?w=2000&fm=jpg';

export class SignatureBenefits extends React.Component {
  state = {
    currentIndex: -1,
    previousIndex: -1
  }

  get className () {
    return classNames({
      'signature-benefits': true,
      'signature-benefits--selected': this.state.currentIndex > -1
    })
  }

  select = (index) => {
    if (index === this.state.currentIndex) return;

    this.setState({
      currentIndex: index,
      previousIndex: this.state.currentIndex
    });
  }

  close = (e) => {
    e.preventDefault();

    this.setState({
      currentIndex: -1,
      previousIndex: -1
    });
  }

  next = () => {
    let next = this.state.currentIndex + 1;
    if (next === this.props.topics.length) next = 0;
    this.select(next);
  }

  prev = () => {
    let next = this.state.currentIndex - 1;
    if (next === -1) next = this.props.topics.length - 1;
    this.select(next);
  }

  get currentTopic () {
    if (this.state.currentIndex === -1) return {};
    return this.props.topics[this.state.currentIndex];
  }

  renderMenu = () => {
    return (
      <div className="signature-benefits__menu">
        <h3 className="signature-benefits__menu__header">
          {this.props.title}
        </h3>
        <ul className="signature-benefits__list">
          { this.props.topics.map((topic, index) => {
            return (
              <li role="button" className="signature-benefits__list__item" onClick={() => this.select(index)}>
                <span>0{index + 1}</span>
                <span>{topic.title}</span>
              </li>
            );
          }) }
        </ul>
      </div>
    )
  }

  renderContent = () => {
    return (
      <div className="signature-benefits__content">
        <div className="signature-benefits__content__header">
          <div className="signature-benefits__content__header__number">0{this.state.currentIndex + 1}</div>
          <div className="signature-benefits__content__header__title">{ this.currentTopic.title }</div>
        </div>
        <div className="signature-benefits__content__content">
          <div className="signature-benefits__content__section">
            <div className="signature-benefits__content__section__title">Features</div>
            <ul>
              { this.currentTopic.features && this.currentTopic.features.split('\n').map(feature => <li><span>{ feature }</span></li>) }
            </ul>
          </div>
          <div className="signature-benefits__content__section">
            <div className="signature-benefits__content__section__title">Impact</div>
            <ul>
              { this.currentTopic.impact && this.currentTopic.impact.split('\n').map(feature => <li><span>{ feature }</span></li>) }
            </ul>
          </div>
        </div>
        <div className="signature-benefits__content__navigation">
          <svg onClick={this.prev} role="button" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.8125 22.666L14.6666 28.2797L19.8125 33.8934" stroke="white" stroke-width="1.51351"/>
            <path d="M14.6671 28.2792L41.332 28.2793" stroke="white" stroke-width="1.51351"/>
            <circle r="27.3333" transform="matrix(-1 0 0 1 28 28)" stroke="white" stroke-width="1.33333"/>
          </svg>

          <svg onClick={this.next} role="button" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36.1875 22.666L41.3334 28.2797L36.1875 33.8934" stroke="white" stroke-width="1.51351"/>
            <path d="M41.3329 28.2792L14.668 28.2793" stroke="white" stroke-width="1.51351"/>
            <circle cx="28" cy="28" r="27.3333" stroke="white" stroke-width="1.33333"/>
          </svg>

          <div className="signature-benefits__content__navigation__close">
            <a href="close" onClick={this.close}>close</a>
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    return (
      <section className={this.className}>
        <div className="signature-benefits__image">
          <picture className="signature-benefits__base">
            <img src={this.props.defaultImage.url + dimensionsDesktop} alt={this.props.title} />
          </picture>
          { this.props.topics.map((topic, index) => {
            let classname = classNames({
              'signature-benefits__topic': true,
              'signature-benefits__topic--active': this.state.currentIndex === index,
              'signature-benefits__topic--previous': this.state.previousIndex === index
            });

            return (
              <picture className={classname}>
                <img src={topic.image.url + dimensionsDesktop} alt={topic.title} />
              </picture>
            );
          }) }
        </div>
        
        <Reveal effect="react-reveal--visible" fraction={0.5}>
          { this.renderMenu() }
          { this.renderContent() }
        </Reveal>
      </section>
    )
  }
}