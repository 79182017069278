import React from 'react';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import Close from './close.inline.svg';

export class ESGWheel extends React.Component {
  state = {};

  init = () => {
    this.setState({
      hoverTopic: -1,
      selectedTopic: -1,
      selectedRelationship: -1
    });

    this.props.layers.forEach((layer, index) => {
      let pie = this.refs.svg.querySelector(`#pie-${index}`);
      pie.addEventListener('mouseover', this.onPieOver);
      pie.addEventListener('mouseout', this.onPieOut);
      pie.addEventListener('click', this.onPieSelect);

      layer.relationshipLayers.forEach((relation, relationIndex) => {
        let label =  this.refs.svg.querySelector(`#label-${index}-${relationIndex}`);
        label.addEventListener('click', this.onRelationSelect);
        label.style.cursor = 'pointer';
      });
    });
  }

  onPieOver = (e) => {
    e.currentTarget.style.cursor = 'pointer';
    let topic = parseInt(e.currentTarget.id.split('-')[1]);
    this.setState({ hoverTopic: topic });
  }

  onPieOut = (e) => {
    this.setState({ hoverTopic: -1 });
  }

  onPieSelect = (e) => {
    let topic = parseInt(e.currentTarget.id.split('-')[1]);

    this.setState({
      selectedTopic: topic,
      selectedRelationship: -1,
      showCenter: true,
      eyebrow: false,
      ...this.props.layers[topic],
    });
  }

  onRelationSelect = (e) => {
    let topic = parseInt(e.currentTarget.id.split('-')[1]);
    let relationship = parseInt(e.currentTarget.id.split('-')[2]);

    this.setState({
      selectedTopic: topic,
      selectedRelationship: relationship,
      showCenter: true,
      icon: this.props.layers[topic].icon,
      eyebrow: this.props.layers[topic].title,
      ...this.props.layers[topic].relationshipLayers[relationship]
    })
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    let svg = this.refs.svg;

    let layer2 = svg.querySelector('#layer-2');
    let layer3 = svg.querySelector('#layer-3');

    // Set hover states on the pies
    this.props.layers.forEach((layer, index) => {
      let pie = svg.querySelector(`#pie-${index}`);
      let opacity = 0.3;
      if (this.state.hoverTopic === -1) opacity = 1;
      if (index === this.state.hoverTopic) opacity = 1;
      pie.lastChild.style.opacity = opacity;
    });

    // Set selected pie
    this.props.layers.forEach((layer, index) => {
      let pie = svg.querySelector(`#overlay-pie-${index}`);
      let opacity = 0;
      if (this.state.selectedTopic === index) opacity = 1;
      pie.style.opacity = opacity;
    });

    // Set the labels opacity
    this.props.layers.forEach((layer, index) => {
      layer.relationshipLayers.forEach((relation, relationIndex) => {
        let label = svg.querySelector(`#label-${index}-${relationIndex}`);
        let opacity = 0.25;

        let active = (
          this.state.selectedTopic === index
          && this.state.selectedRelationship === relationIndex
        );

        if (this.state.selectedTopic > -1) {
          if (this.state.selectedTopic === index) opacity = 1;
        } else {
          if (this.state.hoverTopic === -1) opacity = 1;
          if (this.state.hoverTopic === index) opacity = 1;  
        }

        if (this.state.selectedRelationship > -1) opacity = 0.25;

        if (active) opacity = 0;
        
        label.style.opacity = opacity;

        let selector = `#relations-${index}-${relationIndex}`;
        
        let layer = svg.querySelector(selector);
        

        if (layer) layer.style.opacity = active ? 1 : 0;
      });
    });

    let outerPie = document.querySelector('#layer-1 #pie-chart');
    if (this.state.selectedTopic > -1) {
      layer2.style.opacity = 1;
      outerPie.style.opacity = 0;

    } else {
      layer2.style.opacity = 0;
      outerPie.style.opacity = 1;
    }

    layer3.style.pointerEvents = 'none';
    layer2.style.pointerEvents = 'none';

    if (this.state.selectedRelationship > -1) {
      layer3.style.opacity = 1;
    } else {
      layer3.style.opacity = 0;
      
    }
  }

  close = () => {
    this.setState({
      selectedTopic: -1,
      selectedRelationship: -1,
      showCenter: false
    })
  }

  get className () {
    return classNames({
      'esg-wheel': true,
      'esg-wheel--show-center': this.state.showCenter
    });
  }
  
  render = () => {
    return (
      <section className={this.className} ref="svg">
        <div className="esg-wheel__content">
          <SVG src={this.props.svgFile.url} onLoad={this.init} preProcessor={ svg => svg.replace(/&#x2028;/g," ") }/ >
          <div className="esg-wheel__center">
            <div className="esg-wheel__center__icon">
              <img src={this.state.icon?.url} alt={this.state.title} />
            </div>

            { this.state.eyebrow && (
              <div className="esg-wheel__center__eyebrow">{ this.state.eyebrow }</div>
            )}

            <div className="esg-wheel__center__title">{ this.state.title }</div>
            <div className="esg-wheel__center__body">{ this.state.body }</div>
            <div className="esg-wheel__center__close" onClick={this.close}><Close /></div>
          </div>
        </div>

        <div className="esg-wheel__mobile">
          <img src={this.props.mobile.url} alt="The ESG Wheel" />

          <p className="esg-wheel__mobile__notice">{ this.props.viewOnDesktop }</p>
        </div>
      </section>
    )
  }
}