import React, {useState, useRef, useEffect} from 'react';
import { useScrollPercentage } from 'react-scroll-percentage'
import classNames from 'classnames';

const maxTravel = 125;
const factors = [
  0.2, // Level 1
  -0.8, // Level 2
  -1,   // Level 3 (neutral)
  -1.2, // Level 4
  -1.4  // Level 5
]

export const Parallax = (props) => {
  const content = useRef(null);
  var [ref, percentage = 0] = useScrollPercentage();
  const [visible, setVisible] = useState(false);

  if (props.header) percentage = Math.max(percentage, 0.5);  

  // Main transformation formula
  const transform = (percentage, level) => {
    // Transform the percentage from a 0 to 1 value to a -1 to 1 value
    let relative = ((percentage * 2) - 1);
    let scale = level === 0 ? 1.1 : 1;

    // Calculate the absolute travel distance
    const travel = relative * maxTravel * factors[level];
    return `translate3d(0, ${travel}px, 0) scale3d(${scale},${scale},1)`;
  }

  // Reveal the text when the item is 70pct in the viewport
  if (percentage >= 0.3 && !visible) setVisible(true);

  const update = () => {
    for (var i = 0; i < 5; i++) {
      let selector = `.level-${i + 1}`;
      let items = Array.from(content.current.querySelectorAll(selector));
      items.forEach(item => {
        item.style.transform = transform(percentage, i);
      });
    }
  }

  // Loop through the levels and give them their offsets
  if (content && content.current) {
    requestAnimationFrame(update);
  }

  useEffect(() => {
    update();
  });
  
  const _classNames = classNames({
    'parallax': true,
    'react-reveal--visible': visible
  });  

  return(
    <div ref={ref} className={_classNames}>
      <div ref={content} className="parallax__content">
        { props.children }
      </div>
    </div>
  )
}
