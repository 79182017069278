import React, {useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { TextAnimationHeading } from '../../components/text-animation-heading';
import { TextAnimationBody } from '../../components/text-animation-body';
import Reveal from 'react-reveal/Reveal';
import Slider from 'react-slick/lib/slider';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const ProgressProvider = ({ valueStart, valueEnd, children }) => {
  const [value, setValue] = useState(valueStart);
  useEffect(() => {
    setValue(valueEnd);
  }, [valueEnd]);

  return children(value);
};

export const Testimonials = (props) => {

  const _classNames = classNames({
    'testimonials': true,
  });

  const [endValue, setEndValue] = useState(0)

  const settings = {
    customPaging: function(i) {
      return (
      <figure>
        <ProgressProvider valueStart={0} valueEnd={endValue}>
          {value => 
            <CircularProgressbar className="testimonials__progress-bar"
            value={value}
            strokeWidth={2}
            styles={buildStyles({
              pathTransition: `stroke-dashoffset ${endValue === 0 ? 0 : 5.5}s linear`,
              pathColor: '#0A005F',
              trailColor: 'rgba(4, 0, 31, .1)'
            })}/>
          }
        </ProgressProvider>
        <img src={props.testimonials[i].image.url + '?w=87&h=87&fit=crop' } className="testimonials__progress-bar__head"/>
      </figure>
      )
    },
    onInit: () => {
      setEndValue(100)
    },
    beforeChange: () => {
      setEndValue(0)
    },
    afterChange: () => {
      setTimeout(() => setEndValue(100), 10)
    },
    dots: true,
    autoplay: true,
    autoplaySpeed: 5500,
    dotsClass: "slick-dots slick-thumb",
    draggable: false,
    slidesToShow: 1,
    arrows: false,
    speed: 0
  }



  return(
    <section className={_classNames}>
      <div className="testimonials__container">
        <Reveal effect="react-reveal--visible">
          {useMemo(() => <div className="testimonials__title testimonials__title--color-blue">
            <TextAnimationHeading textStyle="heading-2" text={props.title}/>
          </div>, []
          )}
          <Slider className="slick-slider--initial-anim" {...settings}>
            {props.testimonials.map((testimonial, index) => 
              <div key={index} className="testimonials__slide">
                <div className="testimonials__quote testimonials__quote--color-blue">
                  <TextAnimationBody quote textStyle="large-copy">{testimonial.quote}</TextAnimationBody>
                </div>
                <p className="testimonials__name testimonials__name--color-blue">{testimonial.nameOccupation}</p>
              </div>
            )}
            </Slider>
        </Reveal>
      </div>
    </section>
  )
}
