import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { Button } from '../../components/button';

const ProfileCard = (props) => {

  const { cardsLayout, item, backgroundMode } = props;

  const darkMode = backgroundMode === "dark";

  const _classNames = classNames({
    'profile-section__card-item': true,
    'profile-section__card-item--link': item.detailPage,
    [`profile-section__card-item--${cardsLayout}`]: cardsLayout
  });

  if(item.detailPage){
    return(
      <Link to={item.detailPage.slug} className={_classNames}>
        <div className="profile-section__card-images">
          <figure className={`profile-section__figure ${item.imageCar && "profile-section__figure--has-hover"}`}>
            <img src={item.imagePerson.url + '?w=1000&h=1000&fit=crop&fm=jpg'} alt={item.imagePerson.alt || item.name } />
            {item.imageCar && <img src={item.imageCar.url + '?w=1000&h=1000&fit=crop&fm=jpg'} alt={item.imageCar.alt} />} 
          </figure>
        </div>
        <div className="profile-section__card-content">
          <p className="profile-section__card-name">{item.name}</p>
          <p className="profile-section__card-body">{darkMode ? item.body : item.jobTitle}</p>
        </div>
      </Link>
    )
  } else {
    return(
      <div className={_classNames}>
        <div className="profile-section__card-images">
          <figure className={`profile-section__figure ${item.imageCar && "profile-section__figure--has-hover"}`}>
            <img src={item.imagePerson.url + '?w=1000&h=1000&fit=crop&fm=jpg'} alt={item.imagePerson.alt} />
            {item.imageCar && <img src={item.imageCar.url + '?w=1000&h=1000&fit=crop&fm=jpg'} alt={item.imageCar.alt} />} 
          </figure>
        </div>
        <div className="profile-section__card-content">
          <p className="profile-section__card-name">{item.name}</p>
          <p className="profile-section__card-body">{darkMode ? item.body : item.jobTitle}</p>
        </div>
      </div>
    )
  }
}

export const Profile = (props) => {

  const { backgroundMode, cardsLayout } = props
  const cardProps = {backgroundMode, cardsLayout};

  const _classNames = classNames({
    'profile-section': true,
    [`profile-section--${backgroundMode}`]: backgroundMode
  });

  const tabsHandler = () => {
    
  }


  return(
    <section className={_classNames}>
      <div className="profile-section__container">
        {
          /*
          <div className="profile-section__tabs">
            <span className="profile-section__tab profile-section__tab--active" onClick={tabsHandler}>Tab 1</span>
            <span className="profile-section__tab" onClick={tabsHandler}>Tab 2</span>
          </div>
          */
        }
        <div className="profile-section__cards" >
          {props.personCard?.map(item => (
            <ProfileCard item = {item} {...cardProps} />
          ))}
        </div>
        { props.ctaButtonText && (
          <div className="profile-section__button">
            <Button internal={props.ctaButtonLink?.slug} text={props.ctaButtonText} secondary />
          </div>
        )}
      </div>
    </section>
  )
}