import React, {useState, useEffect, useRef} from 'react';
import SplitType from 'split-type';
import classNames from 'classnames';
import debounce from 'debounce';

var previousWidth = 0;

export const TextAnimationBody = (props) => {
  const el = useRef(null);

  const _classNames = classNames({
    'text-animation-body': true,
    'text-animation-body--quote': props.quote,
    [`as-${props.textStyle}`]: true
  });

  const initialDelay = props.delay || 0;
  const delay = 67;

  useEffect(() => {
    var text;

    const split = () => {
      let paragraphs = el.current.querySelectorAll('p');
      if (paragraphs.length === 0) {
        text = new SplitType(el.current, { types: 'lines' });
        text.lines.forEach((letter, index) => letter.style.animationDelay = `${initialDelay + (delay * index)}ms`);
      } else {
        let counter = 0;
        paragraphs.forEach(paragraph => {
          text = new SplitType(paragraph, { types: 'lines' });
          text.lines.forEach((letter, index) => {
            counter++;
            letter.style.animationDelay = `${initialDelay + (delay * counter)}ms`
          });  
        })
      }

      el.current.classList.add('text-animation-body--parsed');
      previousWidth = window.innerWidth;
    }

    const resize = () => {
      console.log(window.innerWidth, previousWidth);
      if (window.innerWidth === previousWidth) return;
      setTimeout(() => {
        text.revert();
        split();
      }, 200);
    };

    // Wait for fonts to be loaded
    document.fonts.ready.then(split);
    setTimeout(() => {
      window.addEventListener('resize', resize);
    }, 1000);

    return () => {
      window.removeEventListener('resize', resize);
    }
  }, [el]);

  return(
    <span className={_classNames} ref={el}>{ props.children }</span>
  )
}
