import React from 'react';
import classNames from 'classnames';
import { TextAnimationHeading } from '../../components/text-animation-heading';
import { TextAnimationBody } from '../../components/text-animation-body';
import { HtmlText } from '../../components/html-text';
import Reveal from 'react-reveal/Reveal';


export const SectionHeader = (props) => {
  const _classNames = classNames({
    'section-header': true,
    'section-header--side-by-side': !props.stacked,
    'section-header--stacked': props.stacked,
    'section-header--bottom-padding': props.addBottomPadding,
    [`section-header--${props.color}`]: true
  });

  return (
    <div className={_classNames}>
      <div className="section-header__container">
        <div className="section-header__container__row">
          {props.eyebrow &&
            <div className="section-header__container__col">
              <Reveal effect="standard-entry">
                <p className="section-header__eyebrow">{props.eyebrow}</p>
              </Reveal>
            </div>
          }

          {props.title &&
            <div className="section-header__container__col">
              <h1 className="section-header__title">
                <Reveal effect="react-reveal--visible">
                  <TextAnimationHeading text={props.title} textStyle="heading-2" />
                </Reveal>
              </h1>
            </div>
          }

          {props.body &&
            <div className="section-header__container__col section-header__container__col--medium">
              <div className="section-header__description">
                <Reveal effect="react-reveal--visible">
                  <TextAnimationBody textStyle={props.stacked ? "body" : "sub-copy"} delay={165}>
                    <HtmlText html={props.body} />
                  </TextAnimationBody>
                </Reveal>
              </div>
            </div>
          }

        </div>

      </div>
    </div>
  )
}
