import React, { useState, useMemo } from "react";
import classNames from "classnames";
import { FormField } from "../../components/forms";
import { TextAnimationHeading } from "../../components/text-animation-heading";
import { Button } from "../../components/button";
import Reveal from "react-reveal/Reveal";
import { HtmlText } from "../../components/html-text";
import axios from "axios";
import jsonpAdapter from "axios-jsonp";

const endpoint = 'https://myy1awaejh.execute-api.us-east-1.amazonaws.com/contact-form';

export const ContactForm = (props) => {
  const _classNames = classNames({
    "contact-form": true,
  });

  const initialFormData = Object.freeze({
    fname: null,
    lname: null,
    email: null,
    zip: null,
    subject: null,
    message: null,
  });

  const pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
  const [formData, updateFormData] = useState(initialFormData);
  const [formMessage, setFormMessage] = useState();
  const [errorState, setErrorState] = useState(false);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const validateForm = () => {
    for (var prop in formData) {
      if (formData[prop] === "" || formData[prop] === null) {
        setErrorState(true);
        return false;
      }
    }

    setErrorState(false);
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm();
    const isValid = validateForm();

    if (isValid) {
      axios
        .post(endpoint, formData)
        .then((response) => {
          window.scrollTo(0, 0);
          setFormMessage(props.successfulSubscriptionMessage);
        });
    }
  };

  return (
    <section className={_classNames}>
      <div className="contact-form__container">
        <div className="contact-form__container__row">
          <div className="contact-form__container__col contact-form__container__col--left">
            {useMemo(
              () => (
                <Reveal effect="react-reveal--visible">
                  <h2 className="contact-form__heading">
                    {formMessage ? (
                      <h4 className="as-heading-4">
                        <HtmlText html={formMessage} />
                      </h4>
                    ) : (
                      <TextAnimationHeading
                        textStyle="heading-4"
                        key={props.title}
                        text={props.title}
                        delay={165}
                      />
                    )}
                  </h2>
                </Reveal>
              ),
              [formMessage]
            )}
            {!formMessage ? (
              <div>
                <form className="contact-form__form-fields">
                  <FormField
                    type="text"
                    name="fname"
                    placeholder=""
                    label="first name"
                    className="contact-form__form-field"
                    onChange={handleChange}
                    error={
                      formData.fname === "" ||
                      (errorState && formData.fname === null)
                    }
                  />
                  <FormField
                    type="text"
                    name="lname"
                    placeholder=""
                    label="last name"
                    className="contact-form__form-field"
                    onChange={handleChange}
                    error={
                      formData.lname === "" ||
                      (errorState && formData.lname === null)
                    }
                  />
                  <FormField
                    type="email"
                    name="email"
                    placeholder=""
                    label="email"
                    className="contact-form__form-field"
                    onChange={handleChange}
                    error={
                      (formData.email !== null &&
                        !formData.email?.match(pattern)) ||
                      (errorState && formData.email === null)
                    }
                  />
                  <FormField
                    type="text"
                    name="zip"
                    placeholder=""
                    label="zip code"
                    className="contact-form__form-field"
                    onChange={handleChange}
                    error={
                      formData.zip === "" ||
                      (errorState && formData.zip === null)
                    }
                  />
                  <FormField
                    type="dropdown"
                    name="subject"
                    label="subject"
                    className="contact-form__form-field"
                    onChange={handleChange}
                    error={
                      formData.subject === "" ||
                      (errorState && formData.subject === null)
                    }
                    options={props.subject}
                  />
                  <FormField
                    type="textarea"
                    name="message"
                    placeholder=""
                    label="message"
                    rows="8"
                    className="contact-form__form-field"
                    onChange={handleChange}
                    error={
                      formData.message === "" ||
                      (errorState && formData.message === null)
                    }
                  />
                  <Button
                    type="submit"
                    primary
                    text={props.btnText || "submit"}
                    onClick={handleSubmit}
                  />
                </form>
                <p className="contact-form__agreement">
                  <HtmlText html={props.termsAndConditions} />
                </p>
              </div>
            ) : null}
          </div>
          <div className="contact-form__container__col contact-form__container__col--right">
            <div className="contact-form__contact-info">
              {props.contactInfo.map((info) => (
                <div className="contact-form__contact-info__container">
                  <p className="contact-form__contact-info__eyebrow">
                    {info.eyebrow}
                  </p>
                  <p className="contact-form__contact-info__name">
                    {info.name}
                  </p>
                  {props.description && (
                    <p className="contact-form__contact-info__description">
                      {info.description}
                    </p>
                  )}
                  <p className="contact-form__contact-info__phone">
                    {info.number}
                  </p>
                  <p className="contact-form__contact-info__email">
                    {info.email}
                  </p>
                </div>
              ))}
              <div className="contact-form__contact-info__container">
                <p className="contact-form__contact-info__eyebrow">
                  {props.moreInformationTitle}
                </p>
                <p className="contact-form__contact-info__description">
                  <HtmlText html={props.moreInformationBody} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
