import React from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import ShadowX from './shadow-x.inline.svg'
import ShadowL from './shadow-l.inline.svg'
import ShadowM from './shadow-m.inline.svg'
import ShadowS from './shadow-s.inline.svg'

export const TextShadow = (props) => {

  const _classNames = classNames({
    'text-shadow': true,
    [`text-shadow--shadow-${props.shadow}`]: props.shadow
  });

  const isMobile = useMediaQuery({ query: `(max-width: 720px)` })
  const isTablet = useMediaQuery({ query: `(min-width: 720px)` })
  const isLaptop = useMediaQuery({ query: `(min-width: 1024px)` })
  const isDesktop = useMediaQuery({ query: `(min-width: 1440px)` })

  return(
    <div className={_classNames}>
      {isDesktop ? <ShadowX /> : isLaptop ? <ShadowL /> : isTablet ? <ShadowM /> : isMobile ? <ShadowS /> : null }
    </div>
  )
}
