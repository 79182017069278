import React, { useState, useEffect } from "react";
import classNames from "classnames";
import uuid from "react-uuid";
import { Accordion } from "../../components/accordion";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export const Drawer = (props) => {
  const _classNames = classNames({
    drawer: true,
  });

  const [categories, setCategories] = useState(props.section);
  
  // useEffect(() => {
  //   setCategories(
  //     categories.map((item, i) =>
  //       i === 0
  //         ? { ...item, active: true }
  //         : { ...item, active: false }
  //     ))
  // }, []);

  const handleClick = (index) => {
    setCategories(
      categories.map((item, i) =>
      i === index
        ? { ...item, active: true }
        : { ...item, active: false }
    ))
  };

  return (
    <section className={_classNames}>
      <div className="drawer__container">
        <div className="drawer__container__row">
          <div className="drawer__container__col drawer__container__col--left">
            <ul className="drawer__category-navigation">
              {categories.map((item, i) => (
                <li
                  key={uuid()}
                  className={`${
                    item.active
                      ? "drawer__category-navigation__item drawer__category-navigation__item--has-flag"
                      : "drawer__category-navigation__item"
                  }`}
                >
                  {/* <a
                    href={`#${item.category.replace(/\s/g, "")}`}
                    onClick={() => handleClick(i)}
                  >
                    {item.category}
                  </a> */}
                  <AnchorLink
                    to={`#${item.category.replace(/\s/g, "")}`}
                    title={item.category}
                    onAnchorLinkClick={() => handleClick(i)} />
                </li>
              ))}
            </ul>
          </div>

          <div className="drawer__container__col  drawer__container__col--right">
            {categories.map((item, i) => (
              <section
                id={item.category.replace(/\s/g, "")}
                a
                className={`${
                  item.category.active
                    ? "drawer__category__section drawer__category__section--has-flag"
                    : "drawer__category__section"
                }`}
              >
                <h4 className="drawer__category__heading">{item.category}</h4>
                <Accordion items={item.items} />
              </section>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
