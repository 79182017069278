import React from "react";
import classNames from "classnames";
import Reveal from "react-reveal/Reveal";
import { TextAnimationHeading } from "../../components/text-animation-heading";
import { TextAnimationBody } from "../../components/text-animation-body";
import { HtmlText } from "../../components/html-text";
import { Button } from "../../components/button";
import { SignUpForm } from "../../components/sign-up-form";

export const InlineCta = (props) => {
  const _classNames = classNames({
    "inline-cta": true,
    "inline-cta--stacked": props.stacked,
    [`inline-cta--${props.color}`]: true,
  });

  return (
    <div className={_classNames}>
      <div className="inline-cta__container">
        <Reveal effect="react-reveal--visible">
          <div className="inline-cta__container__row">
            {props.title && props.body && (
              <div className="inline-cta__container__col">
                <h4 className="inline-cta__title">
                  <TextAnimationHeading
                    text={props.title}
                    textStyle="heading-4"
                  />
                </h4>
                <div className="inline-cta__description">
                  <TextAnimationBody
                    textStyle={props.stacked ? "body" : "sub-copy"}
                    delay={165}
                  >
                    <HtmlText html={props.body} />
                  </TextAnimationBody>
                </div>
              </div>
            )}

            {(props.ctaButton || props.formButton) && (
              <div className="inline-cta__container__col">
                {props.ctaButton ? (
                  <div className="inline-cta__button">
                    {props.color.includes("night") ? (
                      <Button
                        internal={props.ctaButtonLink?.slug}
                        external={props.externalLink}
                        text={props.ctaButton}
                        primaryDark
                        secondary={props.color[0].includes("night")}
                      />
                    ) : (
                      <Button
                        internal={props.ctaButtonLink?.slug}
                        external={props.externalLink}
                        text={props.ctaButton}
                        primary
                        secondary={props.color[0].includes("night")}
                      />
                    )}
                  </div>
                ) : (
                  <SignUpForm
                    btnText={props.formButton}
                    dark={props.color.includes("night")}
                  />
                )}
              </div>
            )}
          </div>
        </Reveal>
      </div>
    </div>
  );
};
