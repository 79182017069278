import React, {useEffect} from 'react';
import classNames from 'classnames';
import Reveal from 'react-reveal/Reveal';

import Next from './arrow-next.inline.svg';
import Prev from './arrow-prev.inline.svg';

export class FacilityFlyThrough extends React.Component {
  state = {
    currentChapterIndex: 0,
    previousChapterIndex: 0,
    seeking: false,
    video: this.props.videoDesktop.url
  }

  get currentChapter () {
    return this.props.chapters[this.state.currentChapterIndex];
  }

  get previousChapter () {
    return this.props.chapters[this.state.previousChapterIndex];
  }

  loop = () => {
    this.videoUpdate();
    this.videoUpdateGhost();
    if (this.looping) requestAnimationFrame(this.loop);
  }

  componentDidMount () {
    window.addEventListener('resize', this.resize);
    this.looping = true;
    this.loop();
    this.resize();
  }

  componentWillUnmount () {
    this.looping = false;

    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    let video = this.props.videoDesktop.url;
    if (window.innerWidth < 769) video = this.props.videoMobile.url;
    this.setState({ video });
  }

  videoUpdate = () => {
    let time = this.refs.video.currentTime;

    if (time >= this.currentChapter.loopOut) {
      this.refs.video.currentTime = this.currentChapter.loopOut - 1;
    }
  }

  videoUpdateGhost = () => {
    let time = this.refs.ghost.currentTime;

    if (time >= this.previousChapter.loopOut) {
      this.refs.ghost.currentTime = this.previousChapter.loopOut - 1;
    }
  }

  start = () => {
    this.refs.video.play();
    this.refs.ghost.play();
  }

  next = () => {
    let currentChapterIndex = this.state.currentChapterIndex + 1;
    if (currentChapterIndex === this.props.chapters.length) currentChapterIndex = this.state.currentChapterIndex;
    this.setState({ currentChapterIndex });
  }

  prev = () => {
    let index = this.state.currentChapterIndex - 1;
    if (index === -1) index = 0;
    this.seek(index);
  }

  seek = (index) => {
    this.start();

    
    if (index === this.state.currentChapterIndex + 1) return this.next();

    this.refs.ghost.currentTime = this.refs.video.currentTime;

    this.setState({
      currentChapterIndex: index,
      previousChapterIndex: this.state.currentChapterIndex,
    }, () => {
      setTimeout(() => { this.setState({ seeking: true }); }, 50)
      setTimeout(() => { this.refs.video.currentTime = this.currentChapter.loopOut - 1.5; }, 200);
    })

    setTimeout(() => {
      this.setState({ seeking: false });
    }, 800)
  }

  get className () {
    return classNames({
      'facility-fly-through': true,
      'facility-fly-through-seeking': this.state.seeking
    })
  }
 
  render = () => (
    <Reveal fraction={0.2} onReveal={this.start} delay={3000}>
      <section className={this.className}>
        <div className="facility-fly-through__wrapper">
          <div className="facility-fly-through__video">
            <video src={this.state.video} ref="video" playsInline preload muted />
            <video src={this.state.video} ref="ghost" playsInline preload muted className="facility-fly-through__video__ghost" />
          </div>
          <div className="facility-fly-through__bottom">
            <div className="facility-fly-through__content">
              <div className="facility-fly-through__content__number">0{ this.state.currentChapterIndex + 1 }</div>
              <div className="facility-fly-through__content__text">
                <div className="facility-fly-through__content__text__title">{ this.currentChapter.title }</div>
                <div className="facility-fly-through__content__text__body">{ this.currentChapter.body }</div>
              </div>
            </div>
            <div className="facility-fly-through__timeline">
              <div className="facility-fly-through__timeline__next" onClick={this.prev}>
                <Next />
              </div>
              {
                this.props.chapters.map((chapter, index) => {
                  let className = classNames({
                    'facility-fly-through__timeline__chapter': true,
                    'facility-fly-through__timeline__chapter--active': index === this.state.currentChapterIndex,
                    'facility-fly-through__timeline__chapter--last': index === this.props.chapters.length - 1
                  });

                  return (
                    <div className={className}>
                      <div className="facility-fly-through__timeline__chapter__dot" onClick={() => this.seek(index)}/>
                      <div className="facility-fly-through__timeline__chapter__line" />
                    </div>
                  )
                })
              }
              <div className="facility-fly-through__timeline__prev" onClick={this.next}>
                <Prev />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Reveal>
  )
}