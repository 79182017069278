import React, { useEffect } from "react";
import classNames from "classnames";
import Roundy from "roundy";

export const InfographicPlaceholder = (props) => {
  const _classNames = classNames({
    "infographic-placeholder": true,
    [`infographic-placeholder--${props.colorTheme}`]: true,
  });

  return (
    <section className={_classNames}>
      <div className="infographic-placeholder__text">{props.title}</div>
    </section>
  );
};
