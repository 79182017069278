import React from "react";
import classNames from "classnames";
import Reveal from "react-reveal/Reveal";
import Arrow from "./arrow-turqouise.inline.svg";
import { TextAnimationHeading } from "../../components/text-animation-heading";
import { TextAnimationBody } from "../../components/text-animation-body";
import { HtmlText } from "../../components/html-text";
import { Link } from "gatsby";
import { Parallax } from "../../components/parallax";
import { TextShadow } from "../../components/text-shadow";
import { useMediaQuery } from "react-responsive";

export const HomepageHeader = (props) => {
  const _classNames = classNames({
    "homepage-header": true,
  });

  const desktopView = useMediaQuery({ query: "(min-width:1024px)" });
  const tabletView = useMediaQuery({ query: "(min-width:720px)" });
  const mobileView = useMediaQuery({ query: "(max-width:720px)" });

  return (
    <Parallax header={true}>
      <section className={_classNames}>
        {props.mobileVideo.url ||
        props.desktopVideo.url ||
        props.tabletVideo.url ? (
          <video
            className="homepage-header__background-video"
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
            preload="auto"
            width="100%"
            height="100%"
          >
            {desktopView ? (
              <source
                src={props.desktopVideo.url}
                type={`video/${props.desktopVideo.format}`}
              />
            ) : tabletView ? (
              <source
                src={props.tabletVideo.url}
                type={`video/${props.tabletVideo.format}`}
              />
            ) : (
              mobileView && (
                <source
                  src={props.mobileVideo.url}
                  type={`video/${props.mobileVideo.format}`}
                />
              )
            )}
          </video>
        ) : (
          <figure className="homepage-header__background-image level-1">
            <img
              src={
                mobileView
                  ? `${props.backgroundimage.url}?fit=crop&w=375&h=815&fm=jpg`
                  : props.backgroundimage.url
              }
            />
          </figure>
        )}
        <div className="homepage-header__content">
          <TextShadow shadow={props.shadow} />
          <h1 className="homepage-header__title level-4">
            <TextAnimationHeading
              textStyle="homepage-title"
              text={props.title}
              delay={165}
            />
          </h1>
          <div className="homepage-header__body level-3">
            <TextAnimationBody textStyle="large-copy-4" delay={165}>
              <HtmlText html={props.body} />
            </TextAnimationBody>
          </div>
          <div
            className={`homepage-header__subject-blocks ${
              props.gradient && "homepage-header__subject-blocks--gradient"
            }`}
          >
            <div className="subject-blocks-container">
              {props.subjectBlocksList.map((item, index) => (
                <Link
                  key={`subject-${index}`}
                  className="subject-blocks-container__item level-2"
                  to={item.link.slug}
                >
                  <p className="subject-blocks-container__item__eyebrow">
                    {item.eyebrow}
                  </p>
                  <div className="subject-blocks-container__standard-entry">
                    <div className="subject-blocks-container__item__body">
                      <HtmlText html={item.body} />
                    </div>
                    <Arrow className="subject-blocks-container__item__arrow" />
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Parallax>
  );
};
