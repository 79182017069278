import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { TextAnimationBody } from '../../components/text-animation-body/index';
import Reveal from 'react-reveal/Reveal';
import { useMediaQuery } from "react-responsive";


export const QuoteBlock = (props) => {

  const _classNames = classNames({
    'quote-block': true,
    'quote-block--modifier': props.modifier
  });

  const mobileView = useMediaQuery({ query: '(max-width:720px)'});
  const revealFraction = mobileView ? .45 : .7

  return (
    <section className={_classNames} >
      <Reveal effect="react-reveal--visible" fraction={revealFraction}>
        <div className="quote-block__container">
          <div className="quote-block__content" >
            <TextAnimationBody textStyle="large-copy-1">
              { props.body.split('\n').map(p => <p>{p}</p>) }
            </TextAnimationBody>
          </div>
        </div>
      </Reveal>
    </section>
  )
}
