import React, {useEffect} from 'react';
import classNames from 'classnames';
import Reveal from 'react-reveal/Reveal';

import SVG, { Props as SVGProps } from 'react-inlinesvg';
import { Link } from 'gatsby';

const thumbnailDimensions = '?w=116&h=152&fit=crop'

export class FacilityMap extends React.Component {
  state = {
    currentIndex: -1,
    activeSvgLayer: false
  }

  init = () => {
    this.layers = Array.from(document.querySelectorAll('#nacero-facilities-map > g'));
    this.setState({ activeSvgLayer: 'map-base' });
  }

  componentDidUpdate = () => {
    this.layers.forEach(layer => {
      let active = layer.id === this.state.activeSvgLayer;
      layer.style.opacity = active ? 1 : 0;
    })
  }

  selectLayer = (currentIndex) => {
    let activeSvgLayer = this.props.locations[currentIndex].layerId;
    this.setState({ currentIndex, activeSvgLayer });
  }

  render = () => {
    return (
      <section className="facility-map">
        <div className="facility-map__container">
          <div className="facility-map__list">
            { this.props.locations.map((facility, index) => {
              let className = classNames({
                'facility-map__list__tile': true,
                'facility-map__list__tile--active': index === this.state.currentIndex,
              });

              return (
                <div className={className} onClick={() => this.selectLayer(index)}>
                  <div className="facility-map__list__tile__left">
                    <img src={facility.icon.url} alt={facility.title} />
                  </div>
                  <div className="facility-map__list__tile__right">
                    <div className="facility-map__list__tile__title">{ facility.title }</div>
                    <div className="facility-map__list__tile__body">{ facility.description }</div>
                    { facility.linksToPage?.slug && (
                      <Link to={facility.linksToPage?.slug} className="facility-map__list__tile__link">
                        Learn more
                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0 5.08105L10.7137 5.08105" stroke="white" stroke-width="1.02035"/>
                          <path d="M6.63281 1L10.9693 5.0814L6.63281 9.16279" stroke="white" stroke-width="1.02035"/>
                        </svg>
                      </Link>
                    ) }
                  </div>
                </div>
              );  
            })}
          </div>
          <div className="facility-map__map" ref="map">
            <SVG src={this.props.map.url} onLoad={this.init} />
          </div>
        </div>
      </section>
    )
  }   
}