import React, {useEffect} from 'react';
import classNames from 'classnames';

export const Benefits = (props) => {

  const _classNames = classNames({
    'benefits': true
  });

  return(
    <section className={_classNames}>
      <div className="benefits__wrapper">
        <h2 className="benefits__title">{props.title}</h2>
        <p className="benefits__body">{props.body}</p>
        <div className="benefits__cards">
          {props.benefitCards.map((card,index) => 
            <div className="benefits__card" key={index}>
              <img className="benefits__card-image" src={card.image.url} alt={card.image.alt} />
              <p className="benefits__card-title">{card.cardTitle}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
