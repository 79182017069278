import React from 'react';
import classNames from 'classnames';
import Reveal from 'react-reveal/Reveal';
import { TextAnimationBody } from '../../components/text-animation-body';
import { HtmlText } from '../../components/html-text';


export const Story2Columns = (props) => {
  const _classNames = classNames({
    'story-2-columns': true
  });


  return (
    <div className={_classNames}>
      <div className="story-2-columns__container">
        <Reveal effect="react-reveal--visible">
          <div className="story-2-columns__container__row">

            {(props.title && props.body) &&
              <div className="story-2-columns__container__col--left">
                <h4 className="story-2-columns__title">
                  <TextAnimationBody textStyle="large-copy-1" delay={165}>
                    <HtmlText html={props.title} />
                  </TextAnimationBody>
                </h4>
                <div className="story-2-columns__body">
                  <TextAnimationBody textStyle={"body"} delay={165}>
                    <HtmlText html={props.body} />
                  </TextAnimationBody>
                </div>
              </div>
            }

            <div className="story-2-columns__container__col--right">
              { props.image?.url && (
                <figure className="story-2-columns__image">
                  <img src={props.image.url} />
                </figure>
              )}
              
              { props.quote && (
                <div className="story-2-columns__quote">
                  <TextAnimationBody textStyle={"large-copy-3"} delay={165}>
                    {props.quote}
                  </TextAnimationBody>
                </div>
              )}
            </div>
          </div>
        </Reveal>

      </div>
    </div>
  )
}
