import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Roundy from "roundy";
import Raindrop from "./slider-raindrop.inline.svg";
import DonutChart from "react-donut-chart";
import MetricsData from "./metricsData.json";

export const RNGMetrics = (props) => {
  const _classNames = classNames({
    "rng-metrics": true,
    [`rng-metrics--${props.colorTheme}`]: true,
  });

  const [sliderValue, setSliderValue] = useState(10);
  // const [donutValues, setDonutValues] = useState(10);
  const donutValues = MetricsData[sliderValue];

  return (
    <section className={_classNames}>
      <div className="rng-metrics__wrapper">
        <div className="rng-metrics__slider-container">
          <Roundy
            overrideStyle="rng-metrics__slider"
            value={sliderValue}
            arcSize={180}
            min={0}
            max={100}
            rotationOffset={0}
            stepSize={5}
            sliced={false}
            strokeWidth={12}
            thumbSize={0}
            bgColor="#0A005F"
            color="white"
            onChange={(e) => {
              setSliderValue(e);
            }}
          />
          <div className="rng-metrics__slider-content">
            <Raindrop className="rng-metrics__raindrop" />
            <div className="rng-metrics__percents">{sliderValue}%</div>
            <div className="rng-metrics__text">{props.dialLabel}</div>
          </div>
        </div>
        <div className="rng-metrics__donut-container">
          <DonutChart
            className="rng-metrics__donut"
            data={[
              {
                label: "Hydrogen",
                value: donutValues.hydrogen,
              },
              {
                label: "Green",
                value: donutValues.green,
              },
              {
                label: "Blue",
                value: donutValues.blue,
              },
            ]}
            width={560}
            height={560}
            outerRadius={0.8}
            selectedOffset={0}
            clickToggle={false}
            toggledOffset={false}
            strokeColor="#0A005F"
            startAngle={-90}
            onMouseEnter={(item) => console.log(item)}
            legend={false}
            colors={["#8C32FF", "#52FFE0", "#0000D8"]}
          />
          <div className="rng-metrics__donut-content">
            <p className="rng-metrics__donut-eyebrow">{props.chartLabel}</p>
            <span className="rng-metrics__donut-percents">
              {donutValues.reduction}%
            </span>
          </div>
          <div
            className={`rng-metrics__donut-label rng-metrics__donut-label--blue ${
              donutValues.blue === 0 && "rng-metrics__donut-label--hidden"
            }`}
          >
            <p>Nacero<br/>Blue ™</p>
          </div>
          <div
            className={`rng-metrics__donut-label rng-metrics__donut-label--hydrogen `}
          >
            <p>Hydrogen</p>
          </div>
          <div
            className={`rng-metrics__donut-label rng-metrics__donut-label--green ${
              donutValues.green === 0 && "rng-metrics__donut-label--hidden"
            }`}
          >
            <p>Nacero<br/>Green ™</p>
          </div>
        </div>
      </div>
    </section>
  );
};
