import React from "react";
import classNames from "classnames";

import { Button } from "../../components/button";
import { TextAnimationHeading } from "../../components/text-animation-heading";
import { TextAnimationBody } from "../../components/text-animation-body";
import { HtmlText } from "../../components/html-text";
import { Parallax } from "../../components/parallax/index.js";
import { TextShadow } from "../../components/text-shadow";

export const SectionFeature = (props) => {
  const _classNames = classNames({
    "section-feature": true,
    "section-feature--viewport-height": props.useViewportHeight,
  });

  return (
    <Parallax>
      <section className={_classNames}>
        <div className="section-feature__content">
          <TextShadow shadow={props.shadow} />
          {props.eyebrow && (
            <div className="level-5">
              <p className="section-feature__eyebrow">{props.eyebrow}</p>
            </div>
          )}

          {props.title && (
            <h1 className="section-feature__title level-4">
              <TextAnimationHeading
                textStyle="detail-page-title"
                text={props.title}
                delay={165}
              />
            </h1>
          )}

          {props.body && (
            <div className="section-feature__body level-3">
              <TextAnimationBody textStyle="sub-copy" delay={165}>
                <HtmlText html={props.body} />
              </TextAnimationBody>
            </div>
          )}

          {props.ctaButton && (
            <div className="level-2">
              <div className="section-feature__button">
                <Button
                  internal={props.ctaButtonLink?.slug}
                  external={props.ctaButtonExternalLink}
                  text={props.ctaButton}
                  secondaryOutline
                />
              </div>
            </div>
          )}
        </div>
        <figure className="section-feature__background-image level-1">
          <img src={props.backgroundimage.url + '?w=2000&fm=jpg'} />
        </figure>
      </section>
    </Parallax>
  );
};
